import cn from 'clsx'
import React, { FC, useRef, useEffect, useState } from 'react'
import s from './FullHeightSection.module.scss'
import { CustomImage, Fade } from '@components/ui'
import { CustomImageProps } from '@components/ui/CustomImage/CustomImage'
import {
  useIntersectionObserver,
  useWindowSize,
  useEventListener,
} from 'usehooks-ts'
import { useUI } from '@components/ui/context'
import { useHomepageContext } from '@context/HomepageContext'
import { isMobileWidth, isDesktopWidth } from '@utils/helpers'
import { gsap } from 'gsap/dist/gsap'
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin'
import { Player, BigPlayButton, PosterImage, ControlBar } from 'video-react'
import { PlayerReference, StateListener } from 'video-react'
import 'video-react/dist/video-react.css'
import { RequestFile } from '@generated/model/models'

if (typeof window !== 'undefined') {
  // Due to SSR we can only register when we are in the browser
  gsap.registerPlugin(ScrollToPlugin)
}

interface FullHeightSectionProps {
  children?: any
  className?: string
  imageClassName?: string
  image?: CustomImageProps
  imageProps?: any
  source?: RequestFile | null
  el?: HTMLElement
  slideIndex: number
  id: string
}

const FullHeightSection: FC<FullHeightSectionProps> = ({
  children,
  className,
  imageClassName,
  image,
  imageProps,
  source,
  slideIndex,
  el = 'div',
  id,
}) => {
  const {
    activeSection,
    setActiveSection,
    activeSectionHeight,
    setActiveSectionHeight,
    activeSlide,
    setActiveSlide,
    maxHeight,
    setMaxHeight,
  } = useHomepageContext()

  const [localHeight, setLocalHeight] = useState(0)

  const { width, height } = useWindowSize()
  const isMobile = isMobileWidth(width)
  const isHidden =
    activeSlide &&
    activeSlide > 0 &&
    activeSlide != slideIndex &&
    activeSection != 0 &&
    isDesktopWidth(width)

  const rootClassName = isHidden
    ? s.hidden
    : cn(s.root, className, {
        [s.modalActive]: activeSection != 0 && activeSection == activeSlide,
      })

  const mergedImageClassName = cn(
    s.container,
    imageClassName ? imageClassName : ''
  )

  const ref = useRef<HTMLDivElement | null>(null)

  const entry = useIntersectionObserver(ref, {
    threshold: isMobile ? 0.5 : 0.66,
  })

  const isVisible = !!entry?.isIntersecting

  if (isVisible) {
    setActiveSlide(slideIndex)
  }

  const [isPlaying, setIsPlaying] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const [videoState, setVideoState] = useState(null)

  const playerRef = useRef<PlayerReference>(null)

  const playerEntry = useIntersectionObserver(ref, {
    threshold: 0.1,
  })

  const isPlayerVisible = !!playerEntry?.isIntersecting

  const handleClick = (e: React.SyntheticEvent) => {
    setIsPlaying(true)
  }

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const handleStateChange = (state: { ended: any }, prevState: any) => {
    if (state.ended) {
      // video has ended
      playerRef.current?.seek(0)
      playerRef.current?.play()
    }
  }

  playerRef.current &&
    playerRef.current.subscribeToStateChange(handleStateChange)

  useEffect(() => {
    if (!isPlaying && source && isPlayerVisible) {
      setIsPlaying(true)
      playerRef.current?.play()
    } else if (!isPlayerVisible && source && isPlaying) {
      setIsPlaying(false)
      playerRef.current?.pause()
    }
  }, [isPlayerVisible, isPlaying, source])

  useEffect(() => {
    if (ref.current) {
      const maxEl = [...ref.current.children].reduce((cur, acc) =>
        cur.getBoundingClientRect().height > acc.getBoundingClientRect().height
          ? cur
          : acc
      )
      setMaxHeight((maxHeight: number) =>
        maxEl.getBoundingClientRect().height > maxHeight
          ? maxEl.getBoundingClientRect().height
          : maxHeight
      )
      if (ref.current.children) {
        setLocalHeight(
          [...ref.current.children][0].getBoundingClientRect().height
        )
      }
    }
  }, [height, setMaxHeight])

  let Component: React.ComponentType<React.HTMLAttributes<HTMLDivElement>> =
    el as any

  return (
    <div
      ref={ref}
      className={rootClassName}
      id={id}
      style={isMobile && isMounted ? {} : { minHeight: localHeight + 'px' }}
    >
      {children}
      {image && (
        <div className={mergedImageClassName}>
          {source ? (
            <div className={s.videoContainer}>
              {source && isMounted && (
                <div className={s.videoInner} onClick={handleClick}>
                  <Player
                    src={source as string}
                    ref={playerRef}
                    muted
                    playsInline={true}
                    autoPlay={true}
                  >
                    {/* <BigPlayButton position="center" /> */}
                    <ControlBar disableCompletely={true} />
                    <div
                      className={cn(s.poster, {
                        [s.isPlaying]: isPlaying,
                      })}
                    >
                      <CustomImage
                        data={image}
                        layout="fill"
                        objectFit="cover"
                      />
                    </div>
                  </Player>
                </div>
              )}
            </div>
          ) : (
            <>
              <CustomImage
                data={image}
                layout="fill"
                objectFit="cover"
                {...imageProps}
              />
              <Fade className={s.fade} />
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default FullHeightSection
