import { useKeenSlider } from 'keen-slider/react'

import cn from 'clsx'
import s from './PerformanceSections.module.scss'
import React, {
  Children,
  isValidElement,
  useState,
  useRef,
  useEffect,
  FC,
} from 'react'
import { Container, Text, CustomImage, Arrow } from '@components/ui'
import { SectionContainer } from '@components/homepage'
import { HomepagePerformance as HomepagePerformanceType } from '@generated/model/homepagePerformance'

type PerformanceSectionsProps = {
  children: React.ReactNode[]
  className?: string
  id: string
}

const PerformanceSections: FC<PerformanceSectionsProps> = ({
  children,
  className = '',
  id,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isMounted, setIsMounted] = useState(false)
  const sliderContainerRef = useRef<HTMLDivElement>(null)
  const thumbsContainerRef = useRef<HTMLDivElement>(null)

  const [ref, slider] = useKeenSlider<HTMLDivElement>({
    loop: true,
    slides: { perView: 1.2, spacing: 20, origin: 'auto' },
    created: () => setIsMounted(true),
    slideChanged(s) {
      const slideNumber = s.track.details.rel
      setCurrentSlide(slideNumber)
    },
    breakpoints: {
      '(min-width:800px)': {
        slides: {
          perView: 1.5,
          spacing: 40,
          origin: 'center',
        },
      },
    },
  })

  const onPrev = React.useCallback(() => slider.current?.prev(), [slider])
  const onNext = React.useCallback(() => slider.current?.next(), [slider])

  return (
    <>
      <SectionContainer id={id}>
        <Container className={s.container} clean fullWidth>
          <div className={cn(s.root, className)} ref={sliderContainerRef}>
            <div
              ref={ref}
              className={cn(s.slider, { [s.show]: isMounted }, 'keen-slider')}
            >
              {Children.map(children, (child, index) => {
                // Add the keen-slider__slide className to children
                if (isValidElement(child)) {
                  return {
                    ...child,
                    props: {
                      ...child.props,
                      className: cn(
                        s.slide,
                        {
                          [child.props.className]: child.props.className,
                          [s.activeSlide]: currentSlide == index,
                          [s.nonActiveSlide]: currentSlide != index,
                        },
                        'keen-slider__slide'
                      ),
                    },
                  }
                }
                return child
              })}
            </div>
            {isMounted && slider.current && (
              <>
                <Arrow
                  left
                  className={s.arrowLeft}
                  onClick={(e) =>
                    slider.current ? slider.current.prev() : null
                  }
                />

                <Arrow
                  className={s.arrowRight}
                  onClick={(e) =>
                    slider.current ? slider.current.next() : null
                  }
                />
              </>
            )}
          </div>
        </Container>
      </SectionContainer>
    </>
  )
}

export default PerformanceSections
