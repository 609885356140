import React, { createContext, useContext, RefObject, useRef } from 'react'

export type HomepageContextType = {
  activeSection: number | undefined
  setActiveSection: (val: number | undefined) => void
  activeSectionHeight: number | undefined
  setActiveSectionHeight: (val: number | undefined) => void
  activeSlide: number | undefined
  setActiveSlide: (val: number | undefined) => void
  maxHeight: number | undefined
  setMaxHeight: React.Dispatch<React.SetStateAction<number>>
}

export const HomepageContext = createContext<HomepageContextType>({
  activeSection: undefined,
  setActiveSection: (val) => {},
  activeSectionHeight: undefined,
  setActiveSectionHeight: (val) => {},
  activeSlide: undefined,
  setActiveSlide: (val) => {},
  maxHeight: undefined,
  setMaxHeight: (val) => {},
})

export const useHomepageContext = (): HomepageContextType =>
  useContext(HomepageContext)
