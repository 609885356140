import cn from 'clsx'
import s from './PerformanceBlock.module.scss'
import { FC, useState, useRef, useEffect } from 'react'
import {
  FullHeightSection,
  Text,
  CustomImage,
  Button,
  Fade,
} from '@components/ui'
import PerformanceSections from './PerformanceSections/PerformanceSections'
import { HomepagePerformance as HomepagePerformanceType } from '@generated/model/homepagePerformance'
import { ControlBar, Player, PlayerReference } from 'video-react'
import { useIntersectionObserver } from 'usehooks-ts'

type PerformanceBlockProps = {
  data: HomepagePerformanceType
  showSections: boolean
  children?: any
  id: string
}

export const VideoInner: FC<{ section: any }> = ({ section }) => {
  const [isPlaying, setIsPlaying] = useState(false)

  const playerRef = useRef<PlayerReference>(null)
  const ref = useRef<HTMLDivElement | null>(null)

  const playerEntry = useIntersectionObserver(ref, {
    threshold: 0.01,
  })

  const isPlayerVisible = !!playerEntry?.isIntersecting

  const handleStateChange = (state: { ended: any }, prevState: any) => {
    if (state.ended) {
      // video has ended
      playerRef.current?.seek(0)
      playerRef.current?.play()
    }
  }

  playerRef.current &&
    playerRef.current.subscribeToStateChange(handleStateChange)

  useEffect(() => {
    if (!isPlaying && isPlayerVisible) {
      setIsPlaying(true)
      playerRef.current?.play()
    } else if (!isPlayerVisible && isPlaying) {
      setIsPlaying(false)
      playerRef.current?.pause()
    }
  }, [isPlayerVisible, isPlaying])

  return (
    <div className={s.videoInner} ref={ref}>
      <Player src={section.video.toString()} ref={playerRef} muted autoPlay>
        <ControlBar disableCompletely />
        <div
          className={cn(s.poster, {
            [s.isPlaying]: isPlaying,
          })}
        >
          <CustomImage
            data={section}
            layout="fill"
            objectFit="cover"
            className={s.backgroundImagePicture}
          />
        </div>
      </Player>
    </div>
  )
}

const PerformanceBlock: FC<PerformanceBlockProps> = ({
  data,
  showSections,
  children,
  id,
}) => {
  const [showExtended, setShowExtended] = useState(0)

  return (
    <>
      <FullHeightSection className={s.container} slideIndex={5} id={id}>
        {data.sections && (
          <PerformanceSections id={`${id}-sections`}>
            {data.sections.map((section, i) => (
              <div className={s.slide} key={`performance-section_${i}`}>
                <div className={s.slideContent}>
                  <div className={s.detailContainer}>
                    {showExtended != i + 1 && (
                      <Text
                        className={s.detail}
                        variant="sectionHeading"
                        html={section.detail}
                      />
                    )}
                    {section.detail_extended && showExtended == i + 1 ? (
                      <Text html={section.detail_extended} />
                    ) : (
                      ''
                    )}
                    {section.detail_extended && showExtended != i + 1 && (
                      <Button
                        onClick={() => setShowExtended(i + 1)}
                        variant="ghost"
                        className={s.readMore}
                      >
                        Read More
                      </Button>
                    )}
                    {section.detail_extended && showExtended == i + 1 && (
                      <Button
                        onClick={() => setShowExtended(0)}
                        variant="ghost"
                        className={s.readMore}
                      >
                        Read Less
                      </Button>
                    )}
                  </div>
                  {section.citation && (
                    <Text
                      className={s.citation}
                      html={section.citation}
                      variant="text-3"
                    />
                  )}
                  {section.citation_detail && (
                    <Text
                      className={s.citationDetail}
                      html={section.citation_detail}
                      variant="smallText"
                    />
                  )}
                </div>
                <div className={s.backgroundImage}>
                  <div className={s.backgroundImageInner}>
                    {section.video ? (
                      <VideoInner section={section} />
                    ) : (
                      <CustomImage
                        data={section}
                        layout="fill"
                        objectFit="cover"
                        className={s.backgroundImagePicture}
                      />
                    )}
                    <Fade className={s.fade} />
                  </div>
                </div>
              </div>
            ))}
          </PerformanceSections>
        )}
        <div className={s.buttons}>{children}</div>
      </FullHeightSection>
    </>
  )
}

export default PerformanceBlock
