import React from 'react'
import s from './Arrow.module.scss'
import cn from 'clsx'

export interface ArrowProps {
  className?: string
  disabled?: boolean
  left?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const Arrow = (props: ArrowProps) => {
  const classNames = cn(s.root, props.className, {
    [s.rootLeft]: props.left,
    [s.disabled]: props.disabled,
  })

  return (
    <button className={s.button} onClick={props.onClick}>
      <svg
        width="37"
        height="37"
        viewBox="0 0 37 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames}
      >
        {props.left ? (
          <>
            <path
              d="M18.6667 -1.30468e-05C8.54145 -1.3932e-05 0.333335 8.2081 0.333334 18.3333C0.333333 28.4585 8.54144 36.6667 18.6667 36.6667C28.7919 36.6667 37 28.4585 37 18.3333C37 8.2081 28.7919 -1.21617e-05 18.6667 -1.30468e-05Z"
              fill="white"
            />
            <path
              d="M18.6665 11L11.3332 18.3333L18.6665 25.6667"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26 18.3336L11.3333 18.3336"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </>
        ) : (
          <>
            <path
              d="M18.3333 36.6667C28.4586 36.6667 36.6667 28.4586 36.6667 18.3333C36.6667 8.20811 28.4586 0 18.3333 0C8.20811 0 0 8.20811 0 18.3333C0 28.4586 8.20811 36.6667 18.3333 36.6667Z"
              fill="white"
            />
            <path
              d="M18.334 25.6667L25.6673 18.3333L18.334 11"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 18.333H25.6667"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </>
        )}
      </svg>
    </button>
  )
}

export default Arrow
