import { FC, useRef, useEffect } from 'react'
import { Container, Text, CustomImage } from '@components/ui'
import { useWindowSize } from '@hooks/useWindowSize'
import { useHomepageContext } from '@context/HomepageContext'

type SectionContainerProps = {
  children: any
  id: string
}

const SectionContainer: FC<SectionContainerProps> = ({ children, id }) => {
  const ref = useRef<HTMLDivElement>(null)
  const { width, height } = useWindowSize()
  const { setActiveSectionHeight } = useHomepageContext()

  useEffect(() => {
    if (ref.current) {
      setActiveSectionHeight(ref.current.offsetHeight)
    }
  }, [width, height])

  return <div ref={ref} id={id}>{children}</div>
}

export default SectionContainer
